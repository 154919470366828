/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

// $my-primary: mat.define-palette(mat.$deep-purple-palette);
// $my-accent: mat.define-palette(mat.$amber-palette);

$my-theme: mat.define-theme(
  (
    color: (
      primary: mat.$violet-palette,
    ),
    density: (
      scale: -1
    ),
  )
);


html {
  @include mat.core-theme($my-theme);
  @include mat.all-component-themes($my-theme);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* remove native +,- buttons on number inputs */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  display: none;
}
